.container {
	background: #1c1f1e;
	padding: 100px calc(50% - 1540px / 2) 100px;
	border-bottom: 1px solid #717187;
}

.title {
	color: #fff;
	font-size: 36px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	text-align: center;
	margin: 0 0 30px;
	text-wrap: balance;
}

.btn {
	position: relative;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 120%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	min-height: 44px;
	background: var(--primary);
	border-radius: 96px;
	width: 240px;
	padding: 10px 32px;
	max-width: calc(100% - 40px);
	text-wrap: balance;
	margin: 0 auto;
	border: 2px solid var(--primary);
	box-sizing: border-box;
	transition: all 0.5s;

	&:hover {
		background: #fff;
		color: var(--primary);
	}
}

@media screen and (max-width: 1024px) {
	.container {
		padding: 100px 20px 100px;
	}
}
