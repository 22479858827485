.container {
	padding: 0 calc(50% - 1540px / 2) 100px;
}

.title {
	color: #1c1f1e;
	font-size: 36px;
	font-weight: 500;
	line-height: 160%;
	margin: 0 0 32px;
	max-width: 760px;
	letter-spacing: 0.3px;
	text-align: left;
}

.itemsWrapper {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 20px;
}

.item {
	padding: 20px;
	border-radius: 16px;
	border: 1px solid #e9e6ef;
	background: #fff;
	box-shadow: 4px 4px 10px 0px rgba(174, 174, 199, 0.15);
	color: #1c1f1e;
	font-style: normal;
	line-height: normal;
	display: flex;
	flex-direction: column;

	.itemTitle {
		font-size: 20px;
		font-weight: 500;
		margin-bottom: 12px;
		height: 60px;
		display: block;
	}

	.itemBody {
		font-size: 16px;
		font-weight: 400;
		margin-bottom: 60px;
		flex: 1 0 auto;
	}
}

.user {
	display: flex;
	gap: 10px;

	img {
		border-radius: 100px;
		background: #717187;
	}

	h4,
	h5 {
		margin: 0;
	}

	h4 {
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}

	h5 {
		color: #717187;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	.stars {
		margin: auto 0 0;
		flex: 1 0 auto;
		text-align: right;
	}
}

.showMore {
	display: none;
}

@media screen and (max-width: 1600px) {
	.container {
		padding: 0 20px 60px;
		box-sizing: border-box;
		max-width: 100%;
		overflow: hidden;
		width: 1024px;
		max-width: 100%;
		margin: auto;
	}

	.itemsWrapper {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (max-width: 1024px) {
	.itemsWrapper {
		margin: auto;
		max-width: 100%;
		width: 520px;
		grid-template-columns: 1fr;
	}

	.user {
		position: relative;
	}

	.item {
		overflow: hidden;
		transition: all 0.3s;

		.itemTitle {
			font-size: 14px;
			font-weight: 600;
			margin: 0 0 10px;
		}

		.itemBody {
			font-size: 14px;
			margin: 0 0 10px;
			flex: 1 0 auto;
		}

		.stars {
			position: absolute;
			right: 0;
			top: -5px;

			svg {
				width: 20px;
				height: 20px;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.title {
		font-size: 24px;
	}

	.showMore {
		background: none;
		border: none;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: auto;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		color: #000;

		svg {
			transform: rotate(90deg);
		}
	}

	.itemsWrapper {
		gap: 10px;
		padding-bottom: 30px;
		&.close + .showMore svg {
			transform: rotate(-90deg);
		}
	}

	.item {
		&.close {
			display: none;
		}
	}
}
