.container {
	background: #1c1f1e;
	display: grid;
	grid-template-columns: 370px clamp(10px, 5.73vw, 110px) clamp(100px, 19.27vw, 370px) repeat(2, clamp(100px, 12.5vw, 240px)) auto;
	grid-template-rows: 36px auto 34px 95px 0px auto;
	grid-column-gap: 20px;
	grid-row-gap: 20px;
	padding: 100px calc(50% - 1540px / 2);
	justify-content: center;
	max-width: 1540px;
}

.div1 {
	grid-area: 1 / 1 / 3 / 5;
}
.div2 {
	grid-area: 3 / 1 / 7 / 2;
}
.div3 {
	grid-area: 6 / 3 / 7 / 4;
}
.div4 {
	grid-area: 4 / 4 / 7 / 5;
}
.div5 {
	grid-area: 2 / 5 / 5 / 6;
}
.div6 {
	grid-area: 5 / 5 / 7 / 7;
}

.text {
	color: #fff;
	h2 {
		font-size: 36px;
		font-weight: 500;
		line-height: 160%;
		margin: 0 0 32px;
		max-width: 760px;
		letter-spacing: 0.3px;
	}

	p {
		font-size: 16px;
		font-weight: 400;
		line-height: 160%;
		margin: 0;
		max-width: 760px;
	}
}

.affiliate {
	border-radius: 16px;
	background: radial-gradient(circle at left bottom, #cee4fc, #f7d4fa);
	padding: 20px;
	box-sizing: border-box;
	color: #1c1f1e;
	transition: all 0.5s;

	> * {
		transition: all 0.5s;
	}

	h2 {
		font-size: 24px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		margin: 0 0 25px;
	}

	p {
		margin: 0;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 160%;
	}

	.wrapper {
		margin-top: 20px;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;

		img {
			border-radius: 10px;
			object-fit: cover;
			object-position: center 85%;
		}
	}

	.btn {
		color: #1c1f1e;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 100px;
		background: #fff;
		box-shadow: 4px 4px 10px 0px rgba(62, 60, 171, 0.15);
		text-decoration: none;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;

		padding: 10px 32px;
		gap: 10px;

		&.ru {
			padding: 10px 8px;
			white-space: nowrap;
			font-size: 12px;
			gap: 4px;

			svg {
				max-width: 18px;
				max-height: 18px;
			}
		}

		svg {
			transform: rotate(180deg);
		}

		&:hover {
			opacity: 0.8;
		}
	}
}

.info {
	border-radius: 16px;
	background: rgba(0, 0, 0, 0.5);
	padding: 16px 20px;
	color: #bcc4ff;

	&.infoItems {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		div:first-child {
			height: 50%;
			border-bottom: 1px solid #717187;
		}

		div:last-child {
			height: 50%;
			padding: 15px 0 0;
			box-sizing: border-box;
		}
	}

	h2 {
		font-size: 36px;
		font-style: normal;
		font-weight: 500;
		line-height: 160%;
		margin: 0;
	}

	h3 {
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		line-height: 160%;
		margin: 0;
	}

	p {
		margin: 10px 0;
		font-size: 16px;
		line-height: 160%;
		color: #fff;
	}
}

@media screen and (max-width: 1600px) {
	.container {
		display: grid;
		grid-template-columns: 370px 240px 334px;
		grid-template-rows: auto auto auto auto;
		grid-column-gap: 20px;
		grid-row-gap: 20px;
		padding: 100px 20px 60px;
		max-width: 100%;
	}

	.div1 {
		grid-area: 1 / 1 / 2 / 4;
		padding-bottom: 10px;
	}
	.div2 {
		grid-area: 2 / 1 / 4 / 2;
	}
	.div3 {
		grid-area: 4 / 1 / 5 / 2;
	}
	.div4 {
		grid-area: 3 / 3 / 5 / 4;
	}
	.div5 {
		grid-area: 3 / 2 / 5 / 3;
	}
	.div6 {
		grid-area: 2 / 2 / 3 / 4;
	}
}

@media screen and (max-width: 1024px) {
	.container {
		padding: 60px 20px;
		grid-template-columns: 2fr 1fr 1fr;
	}

	.info {
		h3 {
			font-size: clamp(17px, 1.95vw, 20px);
		}

		p {
			font-size: clamp(14px, 1.56vw, 16px);
		}
	}
}

@media screen and (max-width: 768px) {
	.container {
		display: block;
	}

	.text {
		h2 {
			font-size: 24px;
			margin: 0 0 10px;
		}

		p {
			font-size: 14px;
			margin-bottom: 30px;
		}
	}

	.affiliate {
		width: 520px;
		max-width: 100%;
		margin: 0 auto 30px;
		h2 {
			font-size: 16px;
			margin-bottom: 20px;
		}

		p {
			font-size: 14px;
			margin-bottom: 20px;
		}

		.wrapper {
			img {
				min-width: clamp(100px, 34.72vw, 125px);
				min-height: clamp(100px, 34.72vw, 125px);
				width: clamp(100px, 34.72vw, 125px);
				height: clamp(100px, 34.72vw, 125px);
				margin-right: 20px;
			}
		}

		.btn {
			padding: 10px clamp(18px, 8.89vw, 32px);
			font-size: 16px;
			gap: clamp(4px, 2.78vw, 10px);

			svg {
				max-width: clamp(14px, 6.67vw, 24px);
				max-height: clamp(14px, 6.67vw, 24px);
			}
		}
	}

	.info {
		width: 520px;
		max-width: 100%;
		box-sizing: border-box;
		margin: 0 auto 10px;
	}
}
