.container {
	background: linear-gradient(#1c1f1e 53%, #fff 47%);
	padding: 0 calc(50% - 1540px / 2) 100px;
	justify-content: center;
	max-width: 1540px;
	color: #fff;
}

.title {
	font-size: 36px;
	font-weight: 500;
	line-height: 160%;
	margin: 0 0 32px;
	max-width: 760px;
	letter-spacing: 0.3px;
}

.description {
	font-size: 16px;
	font-weight: 400;
	line-height: 160%;
	margin: 0;
	max-width: 760px;
	margin-bottom: 30px;
}

.itemsWrapper {
	display: grid;
	grid-template-columns: repeat(4, 3fr);
	grid-template-rows: repeat(5, 460px);
	gap: 20px;

	.item {
		border-radius: 16px;
		overflow: hidden;
		position: relative;

		img {
			object-fit: cover;
			object-position: center;
			max-width: 100%;
			max-height: 100%;
			height: 100%;
		}

		.text {
			position: absolute;
			bottom: 0;
			padding: 12px;

			h3 {
				margin: 0;
				border-radius: 100px;
				background: rgba(0, 0, 0, 0.5);
				padding: 6px 12px;
				color: #fff;
				font-size: clamp(12px, 0.73vw, 14px);
				font-weight: 400;
				line-height: 24px;
			}
		}
	}

	.item1 {
		grid-area: 1 / 1 / 2 / 3;
	}
	.item2 {
		grid-area: 1 / 3 / 2 / 4;
	}
	.item3 {
		grid-area: 1 / 4 / 2 / 5;
	}
	.item4 {
		grid-area: 2 / 1 / 3 / 2;
	}
	.item5 {
		grid-area: 2 / 2 / 3 / 3;
	}
	.item6 {
		grid-area: 2 / 3 / 3 / 4;
	}
	.item7 {
		grid-area: 2 / 4 / 3 / 5;
	}
	.item8 {
		grid-area: 3 / 1 / 4 / 2;
	}
	.item9 {
		grid-area: 3 / 2 / 4 / 3;
	}
	.item10 {
		grid-area: 3 / 3 / 4 / 5;
	}
	.item11 {
		grid-area: 4 / 1 / 6 / 2;
	}
	.item12 {
		grid-area: 4 / 2 / 5 / 3;
	}
	.item13 {
		grid-area: 4 / 3 / 5 / 4;
	}
	.item14 {
		grid-area: 4 / 4 / 5 / 5;
	}
	.item15 {
		grid-area: 5 / 2 / 6 / 4;
	}
	.item16 {
		grid-area: 5 / 4 / 6 / 5;
	}
}

@media screen and (max-width: 1600px) {
	.container {
		padding: 0 20px 60px;
		box-sizing: border-box;
		max-width: 100%;
		overflow: hidden;
	}

	.title,
	.description {
		width: 984px;
		max-width: 100%;
		margin: 0 auto 30px;
	}

	.itemsWrapper {
		width: 984px;
		margin: auto;
		max-width: 100%;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(5, 290px);
		justify-content: center;
	}
}

.showMore {
	display: none;
}

@media screen and (min-width: 1025px) {
	.itemsWrapper {
		.item {
			.btn {
				transition: all 0.5s;
				opacity: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 100px;
				background: #fff;
				box-shadow: 4px 4px 10px 0px rgba(62, 60, 171, 0.15);
				text-decoration: none;
				font-size: clamp(12px, 0.83vw, 16px);
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				border: none;
				padding: 8px clamp(12px, 1.46vw, 28px);
				margin-bottom: 10px;
				color: #1c1f1e;
				letter-spacing: -0.2px;

				gap: 4px;
				svg {
					transform: rotate(180deg);
				}

				&:hover {
					opacity: 0.8 !important;
				}
			}

			&:hover {
				.btn {
					opacity: 1;
				}
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.itemsWrapper {
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(10, clamp(200px, 52.08vw, 400px));
		width: 100%;
		max-width: 680px;

		.item {
			img {
				width: 100%;
				height: 100%;
				overflow: hidden;
				object-fit: cover;
				object-position: center;
			}

			.text {
				h3 {
					padding: 4px 8px;
					font-size: 10px;
				}
			}

			.btn {
				display: none;
			}
		}

		.item1 {
			grid-area: 1 / 1 / 2 / 3;
		}
		.item2 {
			grid-area: 2 / 1 / 3 / 2;
		}
		.item3 {
			grid-area: 2 / 2 / 3 / 3;
		}
		.item4 {
			grid-area: 4 / 1 / 5 / 3;
		}
		.item5 {
			grid-area: 3 / 2 / 4 / 3;
		}
		.item6 {
			grid-area: 5 / 2 / 6 / 3;
		}
		.item7 {
			grid-area: 5 / 1 / 6 / 2;
		}
		.item8 {
			grid-area: 3 / 1 / 4 / 2;
		}
		.item9 {
			grid-area: 6 / 1 / 7 / 2;
		}
		.item10 {
			grid-area: 6 / 2 / 7 / 3;
		}
		.item11 {
			grid-area: 7 / 1 / 9 / 2;
		}
		.item12 {
			grid-area: 7 / 2 / 8 / 3;
		}
		.item13 {
			grid-area: 8 / 2 / 9 / 3;
		}
		.item14 {
			grid-area: 10 / 1 / 11 / 2;
		}
		.item15 {
			grid-area: 9 / 1 / 10 / 3;
		}
		.item16 {
			grid-area: 10 / 2 / 11 / 3;
		}
	}
}

@media screen and (max-width: 768px) {
	.container {
		background: linear-gradient(#1c1f1e 890px, #fff 891px);
	}

	.title {
		font-size: 24px;
		margin: 0 0 10px;
	}

	.description {
		font-size: 14px;
		margin: 0 0 30px;
	}

	.showMore {
		background: none;
		border: none;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: auto;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		color: #000;

		svg {
			transform: rotate(90deg);
		}
	}

	.itemsWrapper {
		padding-bottom: 30px;
		&.close {
			grid-template-rows: repeat(4, clamp(200px, 52.08vw, 400px));
			+ .showMore svg {
				transform: rotate(-90deg);
			}
		}
	}

	.item {
		&.close {
			display: none;
		}
	}
}
