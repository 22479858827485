.container {
	background: #1c1f1e;
	padding: 0 calc(50% - 1540px / 2) 100px;
}

.title {
	color: #fff;
	font-size: 36px;
	font-weight: 500;
	line-height: 160%;
	margin: 0 0 32px;
	max-width: 760px;
	letter-spacing: 0.3px;
	text-align: left;
}

.useCasesWrapper {
	display: flex;
	gap: 20px;
}

.useCase {
	min-width: clamp(80px, 5.73vw, 110px);
	height: 400px;
	border-radius: 16px;
	background-position: center;
	overflow: hidden;
	transition: all 0.5s;
	position: relative;
	text-decoration: none;

	img {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		object-fit: cover;
	}

	* {
		pointer-events: none;
	}

	&::after {
		content: attr(data-title);
		display: block;
		width: 101%;
		height: 101%;
		background: rgba(0, 0, 0, 0.5);
		transition: all 0.5s;
		color: #fff;
		font-size: 20px;
		font-weight: 400;
		line-height: normal;
		margin: 0;
		opacity: 1;
		writing-mode: vertical-lr;
		text-orientation: revert;
		padding: 12px;
		box-sizing: border-box;
		text-align: left;
		transform: rotate(180deg);
		transform-origin: center;
		position: relative;
	}

	.dataWrapper {
		position: absolute;
		width: 100%;
		opacity: 0;
		background: rgba(0, 0, 0, 0.5);
		bottom: 0;
		display: grid;
		grid-template-columns: 1fr 150px;
		grid-template-rows: 30px auto 48px;
		grid-column-gap: clamp(10px, 0.63vw, 12px);
		grid-row-gap: clamp(8px, 0.63vw, 12px);
		padding: 12px;
		box-sizing: border-box;
		z-index: 100;
	}

	&.active {
		* {
			pointer-events: auto;
		}

		min-width: 630px;

		&::after {
			opacity: 0;
		}

		.dataWrapper {
			opacity: 1;
			transition: opacity 1s ease-out;
		}
	}
}

.itemTitle {
	grid-area: 1 / 1 / 2 / 3;
	color: #fff;
	font-size: 20px;
	font-weight: 400;
	line-height: normal;
	margin: 0;
	white-space: nowrap;
}

.itemDescription {
	grid-area: 2 / 1 / 4 / 2;
	color: #fff;
	font-size: clamp(14px, 0.83vw, 16px);
	font-weight: 400;
	line-height: normal;
	margin: 0;
}

.itemBtn {
	grid-area: 3 / 2 / 4 / 3;
	border-radius: 100px;
	background: #1c1f1e;
	color: #fff;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 4px;
	padding: 10px 0;
	transition: 0.5s;
	text-decoration: none;

	svg {
		transform: rotate(180deg);
	}

	&:hover {
		opacity: 0.8;
		transform: scale(0.95);
	}
}

@media screen and (max-width: 1600px) {
	.container {
		padding: 0 20px 60px;
		box-sizing: border-box;
		max-width: 100%;
		overflow: hidden;
	}

	.title {
		width: 984px;
		max-width: 100%;
		margin: 0 auto 38px;
	}

	.useCasesWrapper {
		width: 984px;
		margin: 0 auto;
		padding: 10px 0 12px;
		box-sizing: content-box;
		gap: 12px;
	}

	.useCase {
		min-width: 89px;

		&.active {
			min-width: 371px;
		}
	}
}

@media screen and (max-width: 1024px) {
	.container {
		padding: 0px 20px 60px;
	}

	.useCasesWrapper {
		flex-direction: column;
		align-items: center;
		width: 100%;
		max-width: 100%;
	}

	.useCase {
		min-width: 0;
		max-width: 100%;
		width: 520px;
		min-height: 90px;
		height: 90px;

		&.active {
			min-width: 0;
			max-width: 100%;
			min-height: 410px;
		}

		&::after {
			writing-mode: horizontal-tb;
			transform: none;
			transform-origin: center;
			padding: 50px 12px 12px;
		}
	}
}

@media screen and (max-width: 768px) {
	.title {
		font-size: 24px;
	}

	.useCasesWrapper {
		padding: 0;
	}

	.useCase {
		.dataWrapper {
			padding: 10px;
			display: block;
			height: fit-content;
		}
	}

	.itemTitle {
		margin-bottom: 10px;
		font-size: 16px;
	}

	.itemDescription {
		margin-bottom: 10px;
		font-size: 14px;
	}

	.itemBtn {
		background: #fff;
		color: #1c1f1e;
		font-size: 16px;
		svg {
			display: none;
		}
	}
}
