.container {
	padding: 35px;
}

.title {
	max-width: 740px;
	color: #1c1f1e;
	text-align: center;
	font-size: 54px;
	font-style: normal;
	font-weight: 600;
	line-height: 190%;
	margin: 0 auto;
	span {
		display: inline-flex;
		justify-content: flex-end;
		gap: 10px;
		align-items: center;
		height: 72px;
		border-radius: 100px;
		border: 2px solid #1c1f1e;
		padding: 0 18px;
		position: relative;
		line-height: 125%;
		top: -5px;
		box-sizing: border-box;
		width: auto;
		overflow: hidden;
		animation: titleLoad 2s ease-in-out;
		white-space: nowrap;
		max-width: fit-content;

		svg {
			color: #1c1f1e;
			min-width: 36px;
		}
	}
}

@keyframes titleLoad {
	0% {
		color: #fff;
		width: 74px;
	}

	40% {
		color: #fff;

		width: 74px;
	}

	100% {
		color: #1c1f1e;
		width: 1000px;
	}
}

.subtitle {
	max-width: 740px;
	margin: 20px auto 30px;
	color: #1c1f1e;
	text-align: center;
	font-size: 16px;
	font-weight: 500;
	line-height: normal;
	text-wrap: balance;
}

.link {
	position: relative;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 120%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	min-height: 44px;
	background: var(--primary);
	border-radius: 96px;
	padding: 10px 18px;
	max-width: calc(100% - 40px);
	text-wrap: balance;
	margin: 0 auto;
	border: 2px solid var(--primary);
	box-sizing: border-box;
	animation: linkLoad 3.5s ease-in-out;

	&:hover {
		background: #ffffff;
		color: var(--primary);
	}
}

@keyframes linkLoad {
	0% {
		bottom: -15px;
		opacity: 0;
	}

	50% {
		bottom: -15px;
		opacity: 0;
	}

	100% {
		bottom: 0;
		opacity: 1;
	}
}

.serviceWrapper {
	display: grid;
	gap: 20px;
	grid-template-columns: 1fr 1fr 1fr;
	width: 1540px;
	margin: clamp(60px, 4.17vw, 80px) auto clamp(25px, 2.08vw, 40px);
	animation: load 1s ease-in-out;
}

@keyframes load {
	0% {
		opacity: 0;
	}

	25% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.features_item {
	border: 1px solid var(--gray, #e9e6ef);
	background: var(--white, #fff);
	box-shadow: 4px 4px 10px 0px rgba(174, 174, 199, 0.15);
	border-radius: 16px;
	overflow: hidden;
	box-sizing: border-box;
	text-decoration: none;
}

.imageWrapper {
	position: relative;
	max-height: 230px;
	background: var(--primary);
	.original,
	.result {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		bottom: 10px;
		border-radius: 100px;
		background: rgba(0, 0, 0, 0.5);
		color: #fff;
		font-size: 14px;
		padding: 6px 12px;
	}

	.original {
		left: 16px;
	}

	.result {
		right: 16px;
	}

	.prompt {
		border-radius: 100px;
		background: rgba(0, 0, 0, 0.5);
		color: #fff;
		position: absolute;
		bottom: 10px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 11;
		white-space: nowrap;
		padding: 6px 12px;
		font-size: clamp(14px, 1.56vw, 16px);

		span {
			color: #717187;
		}
	}
}

.item_img {
	width: 100%;
	max-height: 230px;
	object-fit: cover;
	object-position: center;
}

.item_text {
	padding: 0 15px;
}

.item_title {
	color: #1c1f1e;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin: 6px 0 0;
	width: 100%;
	display: flex;
	justify-content: space-between;

	span {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		color: var(--primary);
		gap: 6px;
		font-size: 16px;
	}
}

.item_next {
	transform: rotate(180deg);
}

.item_description {
	color: #1c1f1e;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
	margin: 12px 0 16px;
}

@media screen and (max-width: 1600px) {
	.container {
		padding: 35px 20px;
	}

	.serviceWrapper {
		width: 100%;
		max-width: 984px;
		grid-template-columns: 1fr 1fr;
	}

	.title {
		font-size: 42px;
		width: 576px;
		span {
			height: 58px;
		}
	}

	@keyframes titleLoad {
		0% {
			color: #fff;
			width: 74px;
		}

		40% {
			color: #fff;
			width: 74px;
		}

		100% {
			color: #1c1f1e;
			width: 442px;
		}
	}

	.serviceWrapper {
		margin: 60px auto 25px;
	}
}

@media screen and (max-width: 1024px) {
	.container {
		padding: 30px 20px;
	}
}

@media screen and (max-width: 768px) {
	.title {
		font-size: clamp(18px, 6.67vw, 24px);
		width: 100%;
		text-wrap: balance;
		text-align: center;
		line-height: 160%;

		span {
			margin: 10px auto 0;
			display: flex;
			height: 38px;
			padding: 8px;
			max-width: fit-content;
			svg {
				width: 18px;
				height: 18px;
				min-width: 18px;
			}
		}
	}

	@keyframes titleLoad {
		0% {
			color: #fff;
			width: 38px;
		}

		40% {
			color: #fff;
			width: 38px;
		}

		100% {
			color: #1c1f1e;
			width: 768px;
		}
	}

	.subtitle {
		font-size: 14px;
		width: 100%;
	}

	.link {
		width: 320px;
	}

	.serviceWrapper {
		width: 320px;
		max-width: 100%;
		grid-template-columns: 1fr;
		gap: 10px;
	}

	.imageWrapper {
		max-height: 190px;
		img {
			max-height: 190px;
		}

		.original,
		.result {
			font-size: 12px;
		}

		.original {
			left: 10px;
		}

		.result {
			right: 10px;
		}
	}

	.item_text {
		padding: 10px 10px 44px;
		position: relative;
	}

	.item_title {
		font-size: 16px;
		margin: 0 0 10px;

		span {
			position: absolute;
			bottom: 10px;
			right: 10px;
		}
	}

	.item_description {
		font-size: 14px;
		margin: 0;
	}
}
