.block {
	margin: 0 auto;
	padding: 0 clamp(77px, 9.9vw, 190px) clamp(60px, 7.12vw, 100px);
	width: 100%;
	background: #ffffff;
	box-sizing: border-box;
	overflow-x: hidden;
	overflow-x: clip;
	overflow-y: visible;
	display: flex;

	&.purple {
		background-color: #f9f8fe;
	}
}

.title {
	color: #1c1f1e;
	font-size: 36px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin: 0;
	min-width: 370px;
}

.btn {
	margin-top: 40px;
	border-radius: 100px;
	background: #1c1f1e;
	display: flex;
	padding: 10px 32px;
	justify-content: center;
	align-items: center;
	gap: 4px;
	color: #fff;
	width: fit-content;
	text-decoration: none;

	svg {
		position: relative;
		left: 0;
		transition: all 0.5s;
	}

	&:hover {
		svg {
			left: 5px;
		}
	}
}

.container {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: clamp(7px, 0.83vw, 16px);
	margin: 0 auto;
	width: 100%;
	max-width: 1280px;
}

.icon {
	&-question {
		position: absolute;
		width: clamp(103px, 9.9vw, 190px);
		height: clamp(160px, 15.62vw, 300px);
		top: -8%;
		left: -12%;
	}

	&-answer {
		position: absolute;
		width: clamp(200px, 19.27vw, 370px);
		height: clamp(120px, 10.94vw, 210px);
		bottom: 5%;
		right: -12%;
	}
}

.item {
	padding: clamp(12px, 1.04vw, 20px);
	background: #ffffff;
	box-shadow: 4px 4px 20px rgba(99, 113, 222, 0.15);
	border-radius: 16px;
	border: 1.5px solid #1c1f1e;

	width: 100%;
	box-sizing: border-box;
	cursor: pointer;
	z-index: 9;
}

.question {
	display: flex;
	font-style: normal;
	justify-content: space-between;
	align-items: center;
	font-size: clamp(14px, 1.04vw, 20px);
	line-height: 120%;
	color: #1c1f1e;
	transition: padding-bottom 0.4s ease-out;

	h3 {
		font-style: initial;
		font-weight: initial;
		font-size: clamp(14px, 1.04vw, 20px);
		margin: 0;
		font-weight: 600;
	}
}

.answer {
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
	display: grid;
	grid-template-rows: 0fr;
	transition: grid-template-rows 0.5s ease-in-out, opacity 0.3s ease-in-out;
	color: #1c1f1e;
	font-size: clamp(12px, 0.94vw, 18px);
	opacity: 0;
	text-align: left;

	> div {
		overflow: hidden;
	}

	img {
		width: 90%;
		max-width: 500px;
	}
}

.arrow {
	margin-left: 10px;
	width: clamp(11px, 0.94vw, 18px);
	min-width: clamp(11px, 0.94vw, 18px);
	height: clamp(6px, 0.52vw, 10px);
	min-height: clamp(6px, 0.52vw, 10px);
}

.question.active .arrow {
	transform: rotate(-180deg);
}

.question.active {
	padding-bottom: clamp(16px, 1.04vw, 20px);
}

.imageWrapper {
	.imageItem {
		display: none;
	}
}

.question.active + .answer {
	transition: grid-template-rows 0.5s ease-in-out, opacity 0.8s ease-in-out;
	opacity: 1;
	grid-template-rows: 1fr;

	.imageWrapper {
		margin-top: 30px;
		display: grid;
		grid-template-columns: repeat(auto-fit, 200px);
		gap: 20px;
		// grid-auto-columns: 200px;
		justify-items: center;
		justify-content: center;

		.imageItem {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			width: 100%;
		}

		.imageText {
			margin: 10px 0 0;
			font-size: clamp(12px, 0.94vw, 18px);
			text-align: center;
		}

		.image {
			width: 100%;
			min-width: 100%;
			height: auto;
		}
	}
}

@media screen and (max-width: 1024px) {
	.block {
		padding: clamp(70px, 8.78vw, 90px) clamp(58px, 7.52vw, 77px) 100px;
	}

	.title {
		font-size: clamp(24px, 3.52vw, 36px);
		min-width: 231px;
		width: 231px;
		margin: 0 20px 0 0;
	}
}

@media screen and (max-width: 768px) {
	.block {
		padding: 0 clamp(6px, 7.55vw, 58px) 100px;
		display: block;
	}

	.title {
		margin: 0 0 24px;
		width: 320px;
		max-width: 100%;
	}

	.btn {
		margin: 0 0 24px;
		width: 320px;
		box-sizing: border-box;
		max-width: 100%;
	}

	.icon-question {
		top: 10%;
		left: -10%;
	}
}

@media screen and (max-width: 480px) {
	.block {
		margin: 0;
		padding: 70px 28px 40px;
	}

	.container {
		width: 100%;
	}
}
